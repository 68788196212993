import React from "react";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

class Intro extends React.Component {
  render() {
    return (
      <div id="intro">
        <h1>
          Mobility, commuting, and contact patterns across the United States
          during the COVID-19 outbreak
        </h1>
        <Divider light />
        <p>
          COVID-19 has dramatically changed our lives and affected how we move,
          shop, work, and interact with each other. In this dashboard we provide
          a set of indicators that summarize how people's behaviors adapted in
          response to the ongoing outbreak.
        </p>
        <p>
          For more coverage about the MOBS lab’s COVID-19 research, see{" "}
          <a
            href="http://mobs-lab.org/2019ncov.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mobs-lab.org/2019ncov.html
          </a>{" "}
          and{" "}
          <a
            href="https://covid19.gleamproject.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://covid19.gleamproject.org/
          </a>
        </p>
        <p><strong>Last updated on September 27th.</strong></p>
        <Button
          variant="contained"
          size="large"
          disableElevation
          color="primary"
          style={{ marginRight: 10 }}
          href="https://www.mobs-lab.org/uploads/6/7/8/7/6787877/assessing_mobility_changes_in_the_united_states_during_the_covid_19_outbreak.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PictureAsPdfIcon style={{ marginRight: 10 }} /> Report 1 (March 31)
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          style={{ marginRight: 10 }}
          href="https://www.mobs-lab.org/uploads/6/7/8/7/6787877/covid19mobility_report2.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PictureAsPdfIcon style={{ marginRight: 10 }} /> Report 2 (May 11)
        </Button>
      </div>
    );
  }
}

export default Intro;
