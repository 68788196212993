import { format, addWeeks, addDays,isBefore } from "date-fns";

const getDates = (lastDate) => {
  const today = new Date();
  const week1 = addWeeks(today, 1);
  const week2 = addWeeks(today, 2);
  const week3 = addWeeks(today, 3);
  const week4 = addWeeks(today, 4);
  return [today, week1, week2, week3]
    .filter((date) => !isBefore(lastDate, date))
    .map((date) => format(date, "LLLL dd, yyyy"));
};

export default getDates;
