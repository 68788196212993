import React from "react";
import axios from "axios";
import { productionUrl } from "../constants/urls";
import { format, parseISO } from "date-fns";

class KeyProjectionResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      peak: "",
      median: "",
      upper: "",
      lower: "",
    };
  }

  async componentDidUpdate(prevProps) {
    const { lastDate } = this.props;

    if (prevProps.lastDate !== lastDate) {
      const response = await axios.get(
        `${productionUrl}projectionResults?date=${lastDate}`
      );
      const { median, upper, peak, lower } = response.data;
      this.setState({
        peak,
        median,
        upper,
        lower,
      });
    }
  }

  render() {
    const { peak, median, upper, lower } = this.state;
    const { lastDate } = this.props;
    return lastDate && peak ? (
      <ul>
        <li>
          Our model points to the days around
          <strong> {format(parseISO(peak), "LLLL d, yyyy")} </strong> as the
          peak time for deaths in the US.
        </li>
        <li>
          Based on the last projections, a total of <strong>{median}</strong>{" "}
          COVID-19 deaths (range of <strong>{lower}</strong> to{" "}
          <strong>{upper}</strong>) are currently projected through{" "}
          {format(parseISO(lastDate), "LLLL d, yyyy")}.
        </li>
      </ul>
    ) : null;
  }
}

export default KeyProjectionResults;
