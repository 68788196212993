import React from "react";

import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";

class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metric: "contactevents_percent_national_county",
      week: "week_01-29_02-04",
    };
  }

  _handleMetricChange = (e) => {
    this.setState({
      metric: e.target.value,
    });
  };

  _handleWeekChange = (e) => {
    this.setState({
      week: e.target.value,
    });
  };

  render() {
    return (
      <Container maxWidth={false} id="maps">
        <Grid container spacing={3} style={{ marginTop: 2 }}>
          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="metric-simple">Metric</InputLabel>
              <Select
                value={this.state.metric}
                onChange={this._handleMetricChange}
                inputProps={{
                  name: "metric",
                  id: "metric-simple",
                }}
              >
                <MenuItem value="contactevents_percent_national_county">
                  Colocation
                </MenuItem>
                <MenuItem value="commute_percent_national_county">
                  Commutes
                </MenuItem>
                <MenuItem value="uniquecontact_percent_national_county">
                  Contacts
                </MenuItem>
                <MenuItem value="mobility_percent_national_county">
                  Mobility
                </MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} className="mapPanel">
            <h3>County-level Average</h3>
            <p>Combined measure, aggregating typical mobility and contact patterns. Percentages refer to the level of the measures with respect to their baseline values in January and February.</p>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="week-simple">Week Of</InputLabel>
              <Select
                value={this.state.week}
                onChange={this._handleWeekChange}
                inputProps={{
                  name: "week",
                  id: "week-simple",
                }}
              >
                <MenuItem value="week_01-29_02-04">Jan 29 - Feb 4</MenuItem>
                <MenuItem value="week_02-05_02-11">Feb 5 - 11</MenuItem>
                <MenuItem value="week_02-12_02-18">Feb 12 - 18</MenuItem>
                <MenuItem value="week_02-19_02-25">Feb 19 - 25</MenuItem>
                <MenuItem value="week_02-26_03-03">Feb 26 - March 03</MenuItem>
                <MenuItem value="week_03-04_03-10">March 4 - 10</MenuItem>
                <MenuItem value="week_03-11_03-17">March 11 - 17</MenuItem>
                <MenuItem value="week_03-18_03-24">March 18 - 24</MenuItem>
                <MenuItem value="week_03-25_03-31">March 25 - 31</MenuItem>
                <MenuItem value="week_04-01_04-07">April 1 - 7 </MenuItem>
                <MenuItem value="week_04-08_04-14">April 8 - 14</MenuItem>
                <MenuItem value="week_04-15_04-21">April 15 - 21</MenuItem>
                <MenuItem value="week_04-22_04-28">April 22 - 28</MenuItem>
                <MenuItem value="week_04-29_05-05">April 29 - May 5</MenuItem>
                <MenuItem value="week_05-06_05-12">May 6 - 12</MenuItem>
                <MenuItem value="week_05-13_05-19">May 13 - 19</MenuItem>
                <MenuItem value="week_05-20_05-26">May 20 - 26</MenuItem>
                <MenuItem value="week_05-27_06-02">May 27 - Jun 2</MenuItem>
                <MenuItem value="week_06-03_06-09">Jun 3 - 9</MenuItem>
                <MenuItem value="week_06-10_06-16">Jun 10 - 16</MenuItem>
                <MenuItem value="week_06-17_06-23">Jun 17 - 23</MenuItem>
                <MenuItem value="week_06-24_06-30">Jun 24 - 30</MenuItem>
                <MenuItem value="week_07-01_07-07">July 1 - 7</MenuItem>
                <MenuItem value="week_07-08_07-14">July 8 - 14</MenuItem>
                <MenuItem value="week_07-15_07-21">July 15 - 21</MenuItem>
                <MenuItem value="week_07-22_07-28">July 22 - 28</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <img
              src={`/mobility_maps/us/collapsed_national_county_${this.state.week}.png`}
              alt="heatmap"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Maps;
