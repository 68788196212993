import React from "react";
import { format, parseISO } from "date-fns";

class Disclaimer extends React.Component {
  render() {
    return (
      <div className="disclaimer">
        <p className="innerDisclaimer">
          Projections updated on:{" "}
          <strong>
            {this.props.projectionDate &&
              format(parseISO(this.props.projectionDate), "LLLL d, yyyy")}
          </strong>
          <br />
          The social distancing projections assume that the lockdown order and
          mitigation policies are in effect until May 4th.
        </p>
      </div>
    );
  }
}

export default Disclaimer;
