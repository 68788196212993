import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MobilityApp from "./mobility/App";
import WhiteHouseApp from "./wh/App";
import ItalyApp from "./italy-public/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/italy">
         <WhiteHouseApp />
          {/* <ItalyApp /> */}
        </Route>
        <Route path="/mobility">
          <MobilityApp />
        </Route>
        <Route path="/usa">
          <WhiteHouseApp />
        </Route>
        <Route path="/">
          <WhiteHouseApp />
        </Route>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
