import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { devUrl } from "../constants/urls";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Paper from "@material-ui/core/Paper";
import axios from "axios";
import getDates from "../constants/dates";
import KeyProjectionResults from "./KeyProjectionResults";

function createData(name, weekly, cumulative) {
  return {
    name,
    weeklyMedian: weekly.median,
    weeklyBounds: weekly.bounds,
    cumulativeMedian: cumulative.median,
    cumulativeBounds: cumulative.bounds,
  };
}

const styles = {
  TableHead: {
    background: "#c8f6e9",
    fontWeight: 600,
  },
};

class SummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        Mitigated: {
          "New Deaths": {median: 0, lower: 0,upper: 0},
          "New Attack Rate": {median: 0, lower: 0, upper: 0},
          "Total Deaths": {median: 0, lower: 0, upper: 0},
          "Attack Rate": {median: 0, lower: 0, upper: 0},
        },
      },
    };
  }

  async componentDidMount() {
    const { date } = this.props;
    const isoDate = new Date().toISOString().split("T")[0];

    const response = await axios.get(
      `${devUrl}aggregateData?state=USA&date=${isoDate}`
    );
    this.setState({
      data: response.data,
    });
  }

  async componentDidUpdate(prevProps) {
    const { state, date } = this.props;
    const isoDate = new Date(this.props.date).toISOString().split("T")[0];

    if (prevProps.state !== state || prevProps.date !== date) {
      const response = await axios.get(
        `${devUrl}aggregateData?state=USA&date=${isoDate}`
      );
      this.setState({
        data: response.data,
      });
    }
  }

  getString = (key, metric, data) => {
    let median = "";
    let bounds = "";
    if (metric === "Attack Rate" || metric === "New Attack Rate") {
      median = `${parseFloat(data[key][metric].median * 100).toFixed(1) || 0}%`;
      bounds = `[${
        parseFloat(data[key][metric].lower.toFixed(3) * 100).toFixed(1) || 0
      }% - ${parseFloat(data[key][metric].upper * 100).toFixed(1) || 0}%]`;
    } else {
      median = `${this.numberWithCommas(
        Math.round(data[key][metric].median || 0)
      )}`;
      bounds = `[${this.numberWithCommas(
        Math.round(data[key][metric].lower || 0)
      )} - ${this.numberWithCommas(Math.round(data[key][metric].upper || 0))}]`;
    }

    return { median, bounds };
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  render() {
    const { data } = this.state;
    console.log(data);
    const { lastDate } = this.props;
    const rows = [
      createData(
        "Deaths",
        this.getString("Mitigated", "New Deaths", data),
        this.getString("Mitigated", "Total Deaths", data)
      ),
      createData(
        "Infection Attack Rate",
        this.getString("Mitigated", "New Attack Rate", data),
        this.getString("Mitigated", "Attack Rate", data)
      ),
    ];

    return (
      <Container maxWidth={false} disableGutters>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 0 }}
          className="summary"
        >
          <Grid item xs></Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                className="form-control"
                style={{ paddingBottom: "15px" }}
              >
                <InputLabel htmlFor="filled-age-native-simple">
                  PROJECTIONS FOR:
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  onChange={(e) => this.props.handleDateChange(e.target.value)}
                >
                  {getDates(lastDate).map((date) => (
                    <option value={date} key={date}>
                      {date}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs></Grid>
            <TableContainer component={Paper}>
              <Table aria-label="summary table">
                <TableHead style={styles.TableHead}>
                  <TableRow>
                    <TableCell style={{ width: 120 }}>USA</TableCell>
                    <TableCell align="center">Weekly Incidence</TableCell>
                    <TableCell align="center">Cumulative Incidence</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.weeklyMedian}
                        <br />
                        {row.weeklyBounds}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.cumulativeMedian}
                        <br />
                        {row.cumulativeBounds}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h2>Key Projection Results</h2>
            <Grid
              item
              xs={12}
              sm={9}
              style={{ paddingBottom: 0, paddingLeft: 0, alignItems: "center" }}
            ></Grid>
            <KeyProjectionResults lastDate={lastDate} />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Container>
    );
  }
}

export default SummaryTable;
