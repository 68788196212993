import React from "react";
import axios from "axios";

import { Container, Grid } from "@material-ui/core";

import Sticky from "react-stickynode";

import Header from "../mobility/components/Header";
import Intro from "../mobility/components/Intro";
import Maps from "../mobility/components/Maps";
import About from "../mobility/components/About";
import Footer from "../mobility/components/Footer";
import Commute from "../mobility/components/Commute";
import Mobility from "../mobility/components/Mobility";
import Contacts from "../mobility/components/Contacts";
import Filters from "../mobility/components/Filters";
import { productionServerUrl } from "../mobility/constants/urls";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableLocations: [],
      selectedStates: ["United States"],
      selectedCSAs: [],
      interventions: {},
    };
  }

  async componentDidMount() {
    const [csaData, interventionData] = await Promise.all([
      axios.get(`${productionServerUrl}CSAs/`),
      axios.get(`${productionServerUrl}interventions`),
    ]);

    // sort array but keep USA at the top
    const responseData = [...csaData.data];
    const locationNames = responseData.map((loc) => loc.name);
    responseData.splice(locationNames.indexOf("United States"), 1);
    responseData.sort((a, b) => (a.name > b.name ? 1 : -1));
    responseData.unshift({ name: "United States", hierarchy: 0, parent: null });
    this.setState({
      availableLocations: responseData,
      interventions: interventionData.data,
    });
  }

  _handleStateSelect = (values) => {
    this.setState({
      selectedStates: values,
    });
  };

  _handleCSASelect = (values) => {
    this.setState({
      selectedCSAs: values,
    });
  };

  render() {
    const {
      availableLocations,
      selectedCSAs,
      selectedStates,
      interventions,
    } = this.state;

    return (
      <div>
        <Container
          disableGutters
          maxWidth={false}
          style={{ marginTop: 0 }}
          id="estimates"
        >
          <Header />
          <Grid
            container
            disableGutters
            spacing={3}
            style={{
              margin: 0,
              padding: 20,
              background: "#f9f9f9",
              width: "100%",
            }}
          >
            <Grid item xs={12} md={4}>
              <Intro />
            </Grid>
            <Grid item xs={12} md={8}>
              <Maps />
            </Grid>
          </Grid>
        </Container>
        <Container
          disableGutters
          maxWidth={false}
          style={{ marginTop: 0 }}
          id="charts"
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={9}>
              <Grid container spacing={3} style={{ marginTop: 40, paddingTop: 20 }}>
                <Contacts
                  selectedLocations={[...selectedStates, ...selectedCSAs]}
                  interventions={interventions}
                />
              </Grid>
              <Grid
                container
                spacing={3}
                style={{
                  background: "#f9f9f9",
                  marginTop: 40,
                  paddingBottom: 40,
                  paddingTop: 20,
                }}
              >
                <Mobility
                  selectedLocations={[...selectedStates, ...selectedCSAs]}
                  interventions={interventions}
                />
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ marginTop: 40, paddingTop: 20, paddingBottom: 40 }}
              >
                <Commute
                  selectedLocations={[...selectedStates, ...selectedCSAs]}
                  interventions={interventions}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{ background: "rgb(236, 253, 246)" }}
            >
              <Sticky enabled top={0} bottomBoundary="#charts">
                <Filters
                  availableLocations={availableLocations}
                  handleStateSelect={this._handleStateSelect}
                  handleCSASelect={this._handleCSASelect}
                />
              </Sticky>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false} disableGutters>
          <About />
        </Container>
        <Container maxWidth={false} style={{ backgroundColor: "#2a383c" }}>
          <Footer />
        </Container>
      </div>
    );
  }
}

export default App;
