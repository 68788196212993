import React from "react";

import { 
  Container, 
  Grid, 
  FormLabel,
  FormControl,
  // FormGroup,
  FormControlLabel,
  // Checkbox,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  LinearProgress,
} from "@material-ui/core";

import axios from "axios";
// import { COLORS } from "../constants/colors";
// import { format } from "date-fns";

import {
  Area,
  Bar,
  // ErrorBar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  ComposedChart,
  // Scatter,
} from "recharts";

import Sticky from "react-stickynode";

import { productionUrl } from "../constants/urls";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infections: false,
      mitigated: true,
      unmitigated: false,
      newDeaths: true,
      data: [],
      scaleValue: "Linear",
      frequencyValue: "weekly",
      loading: true,
    };
  }

  async componentDidMount() {
    const { state } = this.props;
    const response = await axios.get(
      `${productionUrl}data?state=${state}&frequency=weekly`
    );
    this.setState({
      data: response.data,
      loading: false,
    });
  }

  async componentDidUpdate(prevProps) {
    const { state } = this.props;
    const { frequencyValue } = this.state;
    if (prevProps.state !== state) {
      this.setState({loading: true})
      const response = await axios.get(
        `${productionUrl}data?state=${state}&frequency=${frequencyValue}`
      );
      this.setState({
        loading: false,
        data: response.data,
        mitigated: state === "USA" ? this.state.mitigated : true,
      });
    }
  }

  _handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  _handleFrequencyChange = async (e) => {
    const { state } = this.props;
    const frequency = e.target.value;
    this.setState({ loading: true }, () => {
      axios
        .get(`${productionUrl}data?state=${state}&frequency=${frequency}`)
        .then((result) =>
          this.setState({
            loading: false,
            data: result.data,
            frequencyValue: frequency,
          })
        );
    });
  };

  getDataKeys = () => {
    // nonsense we have to do for recharts
    const {
      infections,
      mitigated,
      unmitigated,
      newDeaths,
      frequencyValue,
    } = this.state;
    const dataKeys = [];
    const isWeekly = frequencyValue === "weekly";

    if (infections && mitigated) {
      let key = isWeekly
        ? `Total Infectious per 10,000|Mitigated_weekly`
        : "Total Infectious per 10,000|Mitigated";
      dataKeys.push(key);
    }

    if (infections && unmitigated) {
      let key = isWeekly
        ? `Total Infectious per 10,000|Unmitigated_weekly`
        : "Total Infectious per 10,000|Unmitigated";
      dataKeys.push(key);
    }

    if (newDeaths && unmitigated) {
      let key = isWeekly
        ? `New Deaths|Unmitigated_weekly`
        : "New Deaths|Unmitigated";
      dataKeys.push(key);
    }

    if (newDeaths && mitigated) {
      let key = isWeekly
        ? `New Deaths|Mitigated_weekly`
        : "New Deaths|Mitigated";
      dataKeys.push(key);
    }
    return dataKeys;
  };

  // these are both hacky af but i'm tired

  legendLabels = {
    "Total Infectious per 10,000|Mitigated":
      "Total Infections per 10,000|Mitigated",
    "Total Infectious per 10,000|Unmitigated":
      "Total Infections per 10,000|Unmitigated",
  };

  tickLabels = {
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  };

  getLineName = (key) => {
    // formatting for tooltip / legend based on frequency value
    if (this.state.frequencyValue === "daily") {
      return `${(this.legendLabels[key] || key).split(`|`)[0]}, ${
        key.split(`|`)[1]
      } (Mean)`;
    }

    return `${(this.legendLabels[key] || key).split(`|`)[0]} (Mean)`;
  };

  kFormat = (tickItem) => {
    // format thousands into K, and millions into M
    return Math.abs(tickItem) > 999 && Math.abs(tickItem) < 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000).toFixed(1) + "K"
      : Math.abs(tickItem) > 999999
      ? Math.sign(tickItem) * (Math.abs(tickItem) / 1000000).toFixed(1) + "M"
      : tickItem === 0 || tickItem >= 0.01
      ? tickItem
      : tickItem.toFixed(5);
  };


  
  getCurrentEpiWeek = () => {
    Date.prototype.getWeek = function () {
      var target = new Date(this.valueOf());    
      var dayPs = (this.getDay() + 7) % 7;
      target.setDate(target.getDate() - dayPs + 3);      
      var jan4 = new Date(target.getFullYear(), 0, 4);
      var dayDifference = (target - jan4) / 86400000;      
      if (new Date(target.getFullYear(), 0, 1).getDay() < 4) {
        return 1 + Math.ceil(dayDifference / 7);
      }
      else {
       return Math.ceil(dayDifference / 7);
      }
    };  
    var weekNumber = new Date().getWeek();    
    var year = new Date().getFullYear();

    // console.log (year+'-'+weekNumber);
    
    return year +'-' + (weekNumber + 1);
  }; 

  getDateFromEpiWeek = (epi) => {
    var y = epi.split("-")[0];
    var w = epi.split("-")[1];
    var _days;
    if(w === 53){
    _days = (1 + (w - 1) * 7);
    }else{
    _days = (w * 7); 
    }
    var _date = new Date(y,0,_days);
    _date.setDate(_date.getDate() - _date.getDay() - 1);
    // console.log("marker date: ", _date.toLocaleDateString("fr-CA", { year: 'numeric', month: '2-digit', day: '2-digit' }));
    return _date.toLocaleDateString("fr-CA", { year: 'numeric', month: '2-digit', day: '2-digit' });
  }


  render() {
    const { state } = this.props;
    const { 
      data, 
      scaleValue, 
      // frequencyValue 
    } = this.state;
    const dataKeys = this.getDataKeys();
    // const dateToday = format(new Date(), "yyyy-MM-dd").toString();

    console.log(data);
    // filter out 0s for log scale
    let chartData = [];
    if (this.state.scaleValue === "Logarithmic") {
      chartData = data.filter((row) => {
        const keys = Object.keys(row);
        let returnVal = true;
        keys.forEach((key) => {
          if (row[key] === 0) {
            console.log(key);
            returnVal = false;
          } else if (Array.isArray(row[key])) {
            if (row[key][0] === 0 || row[key[1]] === 0) {
              returnVal = true;
            }
          }
        });
        return returnVal;
      });
    } else {
      chartData = data;
    }

    return (
      <Container maxWidth={false} disableGutters className="chartGrid" id="infdthproj">
         <Sticky enabled top={0} bottomBoundary="#icubedproj">
          <Grid container style={{ paddingTop: 15 }}>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                fullWidth
                className="form-control"
                style={{ paddingBottom: "15px" }}
              >
                <InputLabel
                  htmlFor="filled-age-native-simple"
                  style={{ color: "#2a383c", opacity: 0.3 }}
                >
                  SET LOCATION:
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: "age",
                    id: "filled-age-native-simple",
                  }}
                  onChange={(e) =>
                    this.props.handleStateChange(e.target.value)
                  }
                >
                  {this.props.availableStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs sm={8}></Grid>
          </Grid>
        </Sticky>
        <Grid container spacing={3} style={{ paddingTop: 40 }}>
          <Grid item xs></Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h2>Weekly Deaths in {state}</h2>
              </Grid>
              <Grid item xs={12} sm={10}>
              {this.state.loading ? (
                  <div>
                    <LinearProgress />
                    <h4 style={{ textAlign: "center" }}>Loading data ... </h4>
                  </div>
                ) : (
                <ResponsiveContainer width="100%" height={600}>
                  <ComposedChart
                    margin={{
                      top: 10,
                      right: 60,
                      left: 0,
                      bottom: 80,
                    }}
                    data={chartData}
                    barSize={50}
                    connectNulls
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                      label={{
                        position: "bottom",
                        dy: 10,
                        fontSize: 16,
                      }}
                      scale="auto"
                      tickFormatter={(tick) => {
                        const parts = tick.split("-");
                        return `${this.tickLabels[parts[1]]}/${parts[2] < 12 ? this.tickLabels[parts[2]] : parts[2]}/${parts[0]}`;
                      }}
                      dy={10}
                      dataKey="date"
                      tick={{ fontSize: 14 }}
                      padding={{ left: 0, right: 0 }}
                      allowDuplicatedCategory={true}
                    />
                    <YAxis
                      tick={{ fontSize: 14 }}
                      tickFormatter={(tick) => {
                        return this.kFormat(tick);
                      }}
                      scale={scaleValue === "Logarithmic" ? "log" : "auto"}
                      domain={[0.01, dataMax=>(dataMax*1.8)]}
                      allowDataOverflow={true}
                      label={
                        <Text
                          x={0}
                          y={0}
                          dx={15}
                          dy={250}
                          offset={0}
                          angle={-90}
                          fontSize={16}
                        >
                          Weekly Deaths
                        </Text>
                      }
                    />
                    <Tooltip
                      cursor={{ fill: "#4c5b5f", strokeWidth: 1 }}
                      itemStyle={{
                        fontSize: 14,
                        padding: "0 0 3px 5px",
                        margin: 0,
                        borderLeft: "3px solid",
                      }}
                      formatter={(value, name) => {
                        if (Array.isArray(value)) {
                          if (value[0] && value[1]) {
                            return [
                              `[${Math.round(
                                value[0]
                              ).toLocaleString()} - ${Math.round(
                                value[1]
                              ).toLocaleString()}]`,
                              name,
                            ];
                          } else { 
                            return [null, null]; 
                          }
                        }
                        return [Math.round(value).toLocaleString(), name];
                      }}
                      labelFormatter={(value) => {
                        const parts = value.split("-");
                        return "Week ending on: " + `${this.tickLabels[parts[1]]}/${parts[2] < 12 ? this.tickLabels[parts[2]] : parts[2]}/${parts[0]}`;
                      }}
                      // labelFormatter={(value) => 'Week ending on ' + this.getDateFromEpiWeek(value)}
                      labelStyle={{
                        fontWeight: 600,
                        marginLeft: -20,
                        marginBottom: 10,
                        fontSize: 16,
                      }}
                    />
                    {data.length && (
                      <Legend
                        wrapperStyle={{
                          fontSize: 13,
                          paddingTop: 45,
                          paddingLeft: 10,
                        }}
                      />
                    )}

                    {dataKeys.map((key) => [
                      
                      <Bar
                        dataKey="sd_newdeath"
                        name="Observed"
                        fill="#4F71AE"
                        stroke="#4F71AE"
                        stackId="bars"
                        strokeWidth={0}
                        connectNulls
                      />,

                      <Area
                        name="Projection Range"
                        dataKey={`range_${key}`}
                        stroke="#69CFEF"
                        fill="#69CFEF"
                        fillOpacity="0.25"
                        strokeOpacity="1"
                        strokeWidth={1}
                        legendType="none"
                        dot={false}
                        activeDot={true}
                      />,

                      <Area
                        name="IQR Range"
                        dataKey={`iqr_${key}`}
                        stroke="#69CFEF"
                        fill="#69CFEF"
                        fillOpacity="0.25"
                        strokeOpacity="1"
                        strokeWidth={1}
                        legendType="none"
                        dot={false}
                        activeDot={true}
                      />,

                      <Line
                        name="Projections"
                        dataKey={`amt_${key}`}
                        stroke="#4F71AE"
                        strokeWidth={2}
                        fill="#4F71AE"
                        // connectNulls
                        // stackId="bars"
                        activeDot={{ stroke: "white", strokeWidth: 2, r: 8 }}
                      />,


                      <ReferenceLine
                        x={this.getDateFromEpiWeek(this.getCurrentEpiWeek())}
                        label={{
                          value: "Current EpiWeek",
                          fill: "#7F7F7F",
                          position: "insideTopRight",
                          fontSize: 12,
                          dy: 0,
                          fontWeight: 600,
                        }}
                        stroke="#7F7F7F"
                        strokeDasharray="5 5"
                        ifOverflow="visible"
                      />
                      
                    ])}
                  </ComposedChart>
                </ResponsiveContainer>
              )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" style={{fontSize: 14}}>Scale</FormLabel>
                        <RadioGroup
                          aria-label="scale"
                          name="scale"
                          id="scaleFilter"
                          value={scaleValue}
                          onChange={(e) =>
                            this.setState({
                              scaleValue: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            value="Linear"
                            control={<Radio />}
                            label="Linear"
                          />
                          <FormControlLabel
                            value="Logarithmic"
                            control={<Radio />}
                            label="Logarithmic"
                          />
                        </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </Container>
    );
  }
}

export default Charts;
