import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { productionUrl } from "../constants/urls";

makeStyles((theme) => ({
  root: {
    paddingTop: 120,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

class Filters extends React.Component {
  async componentDidMount() {
    const stateData = await axios.get(`${productionUrl}states`);
    this.props.handleAvailableStatesChange(stateData.data);
  }
  _handleDateChange = (e) => {
    this.props.handleDateChange(e.target.value);
  };

  _handleStateChange = (e) => {
    this.props.handleStateChange(e.target.value);
  };

  render() {
    return (
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={0} className="filtercontainer">
          <Grid
            item
            xs={6}
            sm={3}
            style={{ paddingBottom: 0, paddingLeft: 0, alignItems: "flex-end" }}
          >
            {/* <FormControl
              fullWidth
              className="form-control"
              style={{ paddingBottom: "15px" }}
            >
              <InputLabel
                htmlFor="filled-age-native-simple"
                style={{ color: "#2a383c", opacity: 0.3 }}
              >
                PROJECTIONS FOR:
              </InputLabel>
              <Select
                native
                inputProps={{
                  name: "age",
                  id: "filled-age-native-simple",
                }}
                onChange={this._handleDateChange}
              >
                <option value={"April 3, 2020"}>April 3, 2020</option>
                <option value={"April 17, 2020"}>April 17, 2020</option>
                <option value={"May 1, 2020"}>May 1, 2020</option>
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs sm></Grid>
        </Grid>
      </Container>
    );
  }
}

export default Filters;
