import React from "react";
import axios from "axios";

import { Container, Grid, Switch, Typography } from "@material-ui/core";

import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  ReferenceLine,
} from "recharts";

import { productionServerUrl } from "../constants/urls";
import { COLORS } from "../constants/colors";

const styles = {
  tightenUp: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      index: false,
      timescale: "date",
      scaleValue: "auto",
    };
  }

  async componentDidMount() {
    const dataResponse = await axios.get(
      `${productionServerUrl}data?metric=mobility&CSAs=["United States"]`
    );

    this.setState({
      data: dataResponse.data,
    });
  }

  async componentDidUpdate(prevProps) {
    const { selectedLocations } = this.props;
    if (prevProps.selectedLocations !== selectedLocations) {
      const valString = selectedLocations.map((val) => `"${val}"`).join(",");
      const response = await axios.get(
        `${productionServerUrl}data?metric=mobility&CSAs=[${valString}]`
      );
      this.setState({
        selectedLocations,
        data: response.data,
      });
    }
  }

  _handleAxisChange = () => {
    this.setState({
      index: !this.state.index,
    });

    !this.state.index ? 
      this.setState({
        scaleValue: "log"
      }) : 
      this.setState({
        scaleValue: "auto"
      })
  };

  getDataKeys = () => {
    const { selectedLocations } = this.props;
    const { index } = this.state;

    return selectedLocations.map((name) => {
      if (index) return `amt_mobility_index_${name}`;
      return `amt_mobility_percent_${name}`;
    });
  };

  getTooltipLabel(key) {
    const parts = key.split("_");
    return parts;
  }

  tickLabels = {
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "05": "5",
    "06": "6",
    "07": "7",
    "08": "8",
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  };

  render() {
    const dataKeys = this.getDataKeys();
    const { data } = this.state;
    const { interventions } = this.props;
    return (
      <Container maxWidth={false} className="chartGrid" id="mobility">
        <Grid container spacing={3} style={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <h2>Mobility</h2>
                <p>
                As a proxy for individual mobility, we calculate the radius of <a href="https://www.nature.com/articles/nature06958" target="_blank">gyration</a> of anonymous users throughout a day. The radius of gyration measures the mean square deviation of the distances traveled by a user on a given day, as measured from the center of mass of the trajectory. Larger radii of gyration correspond to trajectories with positions that are far away from the average position. We compute a daily statistic that is proportional to the radius of gyration for the users living in the selected location. In the following, <em>index</em> is proportional to the value of this statistic while <em>typical behavior</em> shows how each day compares to baseline values of the statistic in the same weekday from January and February (e.g. the value on Tuesday April 7th is compared to a typical Tuesday in January/February).
                </p>
              </Grid>
              <Grid item xs sm>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    maxWidth="xs"
                  >
                    <Grid
                      item
                      xs={4}
                      style={{
                        ...styles.tightenUp,
                        fontSize: 12,
                        lineHeight: "14px",
                        textAlign: "right",
                      }}
                      className={!this.state.index ? "on" : "off"}
                    >
                      Typical Behavior
                    </Grid>
                    <Grid item xs={2} style={styles.tightenUp}>
                      <Switch
                        checked={this.state.index}
                        onChange={this._handleAxisChange}
                        name="index"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        ...styles.tightenUp,
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                      className={!this.state.index ? "off" : "on"}
                    >
                      Index
                    </Grid>
                  </Grid>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    maxWidth="xs"
                  >
                    <Grid
                      item
                      xs={4}
                      style={{
                        ...styles.tightenUp,
                        fontSize: 12,
                        lineHeight: "14px",
                        textAlign: "right",
                      }}
                      className={this.state.timescale === "date" ? "on" : "off"}
                    >
                      Date
                    </Grid>
                    <Grid item xs={2} style={styles.tightenUp}>
                      <Switch
                        checked={this.state.timescale === "days"}
                        onChange={() => {
                          this.setState({
                            timescale:
                              this.state.timescale === "date" ? "days" : "date",
                          });
                        }}
                        name="timescale"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        ...styles.tightenUp,
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                      className={this.state.timescale === "days" ? "on" : "off"}
                    >
                      Days Since 1st Reported Case
                    </Grid>
                  </Grid>
                  <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                      maxWidth="xs"
                      className={!this.state.index ? "fade" : null}
                    >
                      <Grid
                        item
                        xs={4}
                        style={{
                          ...styles.tightenUp,
                          fontSize: 12,
                          lineHeight: "14px",
                          textAlign: "right",
                        }}
                        className={
                          this.state.scaleValue === "auto" ? "on" : "off"
                        }
                      >
                        Linear
                      </Grid>
                      <Grid item xs={2} style={styles.tightenUp}>
                        <Switch
                          checked={this.state.scaleValue === "log"}
                          disabled={!this.state.index}
                          onChange={() => {
                            this.setState({
                              scaleValue:
                                this.state.scaleValue === "auto"
                                  ? "log"
                                  : "auto",
                            });
                          }}
                          name="scaleValue"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          ...styles.tightenUp,
                          fontSize: 12,
                          lineHeight: "14px",
                        }}
                        className={
                          this.state.scaleValue === "log" ? "on" : "off"
                        }
                      >
                        Log
                      </Grid>
                    </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={500}>
                  <ComposedChart
                    width={730}
                    height={250}
                    data={
                      data[
                        this.state.timescale === "date"
                          ? "dateData"
                          : "daysData"
                      ]
                    }
                    margin={{ top: 5, right: 30, left: 20, bottom: 80 }}
                  >
                    <CartesianGrid stroke="#efefef" />
                    <XAxis
                      // uncomment this if data is not sorted?
                      //  type={
                      //   this.state.timescale === "days" ? "category" : "category"
                      // }
                      label={{
                        value:
                          this.state.timescale === "date"
                            ? "Date"
                            : "Days Since 1st Reported Case",
                        dy: 10,
                        fontSize: 16,
                        position: "bottom",
                      }}
                      dataKey={
                        this.state.timescale === "date" ? "date" : "days"
                      }
                      tick={{
                        fontSize: 12,
                      }}
                      tickFormatter={(tick) => {
                        if (this.state.timescale === "days") return tick;
                        const parts = tick.split("-");
                        return `${this.tickLabels[parts[1]]}/${parts[2]}`;
                      }}
                      domain={['dataMin', 'dataMax']}
                      // interval={
                      //   this.state.timescale === "days" 
                      //   ? 7 
                      //   : "preserveEnd"
                      // }
                    />
                    <YAxis
                      label={{
                        value: this.state.index
                          ? "Mobility Index"
                          : "Mobility Typical Behavior",
                        dx: -30,
                        fontSize: 16,
                        angle: -90,
                      }}
                      tick={{
                        fontSize: 12,
                      }}
                      tickFormatter={(tick) => {
                        if (!this.state.index) {
                          return `${tick}%`;
                        }
                        if (tick < 1) {
                          return tick.toFixed(2);
                        }
                        return tick;
                      }}
                      domain={this.state.scaleValue === "log" ? ['auto', 'auto'] : [0, 'auto']}
                      scale={this.state.scaleValue}
                    />
                    <Tooltip
                      labelStyle={{
                        marginLeft: -20,
                        marginBottom: 10,
                        fontSize: 18,
                      }}
                      itemStyle={{
                        borderLeft: "3px solid",
                        padding: "0 0 3px 5px",
                      }}
                      formatter={(val) => {
                        if (this.state.index) {
                          return val.toFixed(3);
                        }
                        return `${val.toFixed(2)}%`;
                      }}
                    />
                    <Legend
                      wrapperStyle={{
                        fontSize: 12,
                        marginBottom: 10,
                        paddingTop: 45,
                      }}
                    />
                    {dataKeys.map((key) => {
                      const location = key.split("_")[3];
                      const intervention = interventions[location] || {};
                      const shelterInPlace = intervention.shelterInPlace;
                      const stateOfEmergency = intervention.stateOfEmergency;

                      const lineComponents = [
                        <Line
                          type="monotone"
                          name={this.getTooltipLabel(key)[3]}
                          dataKey={key}
                          stroke={COLORS[dataKeys.indexOf(key)]}
                          key={`${key}_line`}
                          dot={false}
                          activeDot={{ r: 6, stroke: "#ffffff" }}
                          strokeWidth={2}
                        />,
                      ];

                      if (dataKeys.length === 1) {
                        if (shelterInPlace) {
                          lineComponents.push(
                            <ReferenceLine
                              x={shelterInPlace}
                              key={`${key}_shelter`}
                              label={{
                                value: `Shelter in Place - ${location}`,
                                angle: 270,
                                fontSize: 10,
                                position: "insideBottomLeft",
                                dx: 10,
                              }}
                            />
                          );
                        }
                        if (stateOfEmergency) {
                          lineComponents.push(
                            <ReferenceLine
                              x={stateOfEmergency}
                              key={`${key}_som`}
                              label={{
                                value: `State of Emergency - ${location}`,
                                angle: 270,
                                fontSize: 10,
                                position: "insideBottomLeft",
                                dx: 10,
                              }}
                            />
                          );
                        }
                      }
                      if (!this.state.index) {
                        lineComponents.push(
                          <ReferenceLine
                            y={100}
                            stroke="#999999"
                            strokeDasharray="3 3"
                            label={{
                              value: `Typical Behavior`,
                              angle: 0,
                              fill: "#999999",
                              fontSize: 10,
                              position: "insideBottomRight",
                              dx: 10,
                            }}
                          />,
                          <ReferenceLine
                            y={50}
                            stroke="#999999"
                            strokeDasharray="3 3"
                            label={{
                              value: `50% Reduction`,
                              angle: 0,
                              fill: "#999999",
                              fontSize: 10,
                              position: "insideBottomRight",
                              dx: 10,
                            }}
                          />,
                        );
                      }
                      if (this.state.timescale === "days") {
                        console.log("timescale: ", this.state.timescale);
                        lineComponents.push(
                          <ReferenceLine
                            x={0}
                            stroke="#999999"
                            strokeDasharray="3 3"
                            label={{
                              value: `First reported case`,
                              angle: 0,
                              fill: "#999999",
                              fontSize: 10,
                              position: "insideTopLeft",
                              dx: 5,
                            }}
                          />,
                        );
                      }
                      return lineComponents;
                    })}
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Charts;
