import React from "react";
import { format, parseISO } from "date-fns";
import {
  Grid,
} from "@material-ui/core";

class Disclaimer extends React.Component {
  render() {
    return (
      <Grid container disableGutters style={{background:"#F1FFFB"}} maxWidth={false}>
        <Grid item xs={1}></Grid>
        <Grid item xs>
          <div>
            <p className="innerDisclaimer" style={{fontSize: 14}}>
              Projections updated on: <strong>
                {this.props.projectionDate &&
                  format(parseISO(this.props.projectionDate), "LLLL d, yyyy")}
              </strong>  
            </p>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Disclaimer;
